import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { formatISO } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Toggle from "@/components/forms/Toggle";

const FiltersSchema = Yup.object().shape({
  search: Yup.string(),
  property: Yup.string(),
  status: Yup.string(),
  start_date: Yup.string(),
  end_date: Yup.string(),
  only_managed_leads: Yup.boolean(),
});

const AutoSubmit = ({ values, submitForm }) => {
  useEffect(() => {
    submitForm(values);
  }, [values, submitForm]);

  return null;
};

AutoSubmit.propTypes = {
  values: PropTypes.object.isRequired,
  submitForm: PropTypes.func.isRequired,
};

export default function Filters({ onSubmit, onReset, managedLeads }) {
  const submit = (values) => {
    const v = { ...values };
    Object.keys(v).forEach((key) => {
      if (v[key] === "") {
        delete v[key];
      }
    });

    if (v.start_date && v.end_date) {
      v.created_range = `${formatISO(v.start_date.setHours(0, 0, 0), {
        representation: "complete",
      })},${formatISO(v.end_date.setHours(23, 59, 59), {
        representation: "complete",
      })}`;
    }
    v.page = 1;
    onSubmit(v);
  };

  return (
    <div>
      <Formik
        initialValues={{
          search: "",
          property: "",
          status: "1",
          start_date: "",
          end_date: "",
          only_managed_leads: false,
          managed_leads: managedLeads,
        }}
        validationSchema={FiltersSchema}
        onSubmit={submit}
      >
        {({ values, resetForm, setFieldValue, submitForm }) => (
          <Form>
            <AutoSubmit values={values} submitForm={submitForm} />
            <div className="md:flex md:flex-wrap md:gap-3 md:pr-3">
              <div className="xl:w-4/12">
                <label className="form-label" htmlFor="search">
                  Buscar por nombre, email o teléfono
                </label>
                <Field name="search" className="input" placeholder="Smith" />
                <ErrorMessage
                  component="span"
                  className="input-error"
                  name="search"
                />
              </div>
              <div>
                <label className="form-label" htmlFor="property">
                  ID Propiedad
                </label>
                <Field name="property" className="input" placeholder="123" />
                <ErrorMessage
                  component="span"
                  className="input-error"
                  name="property"
                />
              </div>
              <div>
                <label className="form-label" htmlFor="status">
                  Estatus
                </label>
                <Field as="select" name="status" className="input">
                  <option value="all">Todos</option>
                  <option value="1">Pendiente</option>
                  <option value="2">Contactado</option>
                  <option value="3">Descartado</option>
                </Field>
                <ErrorMessage
                  component="span"
                  className="input-error"
                  name="status"
                />
              </div>
              <div className="grow">
                <label className="form-label">Periodo de contactos</label>
                <div className="flex space-x-1">
                  <DatePicker
                    className="input"
                    selectsRange={true}
                    startDate={values.start_date}
                    endDate={values.end_date}
                    onChange={([start_date, end_date]) => {
                      setFieldValue("start_date", start_date);
                      setFieldValue("end_date", end_date);
                    }}
                  />
                </div>
              </div>
              {managedLeads && (
                <div className="grow">
                  <label className="form-label">
                    Sólo contactos perfilados
                  </label>
                  <div className="flex items-center space-x-2">
                    <Toggle
                      type="checkbox"
                      name="only_managed_leads"
                      className="toggle-input"
                      status={values.only_managed_leads}
                      onChange={(e) => {
                        setFieldValue("only_managed_leads", e);
                        setFieldValue("managed_leads", managedLeads);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="mt-3">
              <button
                onClick={() => {
                  resetForm();
                  onReset();
                }}
                type="button"
                className="button-secondary"
              >
                Limpiar filtros
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

Filters.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  managedLeads: PropTypes.bool,
};

Filters.defaultProps = {
  onSubmit: () => {},
  onReset: () => {},
};
