import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "@uidotdev/usehooks";

import LoginForm from "./forms/LoginForm";
import { authUser } from "@/_actions/authActions";
import apiClient from "@/utils/apiClient";

import Notification from "@/components/overlays/Notification";
import logoCYT from "./img/logo-blue.svg";
import cognitoMessages from "@/constants/cognitoMessages";

export default function Login() {
  const [showNotification, toggleNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [honeypotSessionData, setHoneypotSessionData] = useSessionStorage(
    "honeypotSession",
    {}
  );

  let navigate = useNavigate();

  async function getUserData() {
    try {
      const res = await apiClient.get("/accounts/me/");
      setUserReport(res.data[0]);
    } catch (err) {
      $log.error(err);
    }
  }

  function setUserReport(user) {
    try {
      if (typeof window !== "undefined") {
        if (window?.ga) {
          ga("set", "userId", user.id);
        }

        const honeypotSession = {
          email: user.email,
          user_name: `${user.first_name} ${user.last_name} `,
          custom_fields: {
            user_id: user.id,
            contract_start_date: user.contract_dates.start_date,
            contract_end_date: user.contract_dates.end_date,
            business_name: user.business_name,
            client_id: user.client,
            role: user.role,
            phone: user.contact.mobile,
          },
        };
        setHoneypotSessionData(honeypotSession);
      }
    } catch (err) {
      $log.error(err);
    }
  }

  const submitLoginForm = async ({ username, password }) => {
    try {
      await authUser(username, password);
      await getUserData();
      navigate("/contacts");
    } catch (err) {
      toggleNotification(true);
      setErrorMessage(
        cognitoMessages[err.message] ||
          "Ocurrió un error, intenta de nuevo más tarde"
      );
      $log.error(err);
    }
  };

  return (
    <>
      <div className="flex min-h-screen">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <div className="flex flex-shrink-0 items-center px-4">
                <img className=" h-12 w-auto" src={logoCYT} alt="logo-blue" />
              </div>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Ingreso a usuarios
              </h2>
              <p className="mt-2  text-sm text-gray-600">
                O{" "}
                <a
                  href="/unirse"
                  className=" text-md font-medium text-blue-900 hover:text-blue-800"
                >
                  crea una cuenta
                </a>
              </p>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <LoginForm onSubmit={submitLoginForm} />
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
      <Notification
        open={showNotification}
        setOpen={toggleNotification}
        timeOut={10000}
        type="error"
        title={errorMessage}
      />
    </>
  );
}
