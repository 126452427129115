import React from "react";
import PocketBase from "pocketbase";
import Modal from "@/components/overlays/Modal";
import classNames from "classnames";
import { Annotorious, ImageAnnotator } from "@annotorious/react";
import * as rasterizeHTML from "rasterizehtml";
import { useSessionStorage } from "@uidotdev/usehooks";
import Notification from "@/components/overlays/Notification";

import "@annotorious/react/annotorious-react.css";

export default function ReportBug() {
  const [showReport, setShowReport] = React.useState(false);
  const [showNotification, toggleNotification] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [errors, setErros] = React.useState([]);
  const [comment, setComment] = React.useState("");
  const [takingScreenshot, setTakingScreenshot] = React.useState(false);
  const [imgData, setImgData] = React.useState("");
  const canvasRef = React.useRef(null);
  const [honeypotSessionData, setHoneypotSessionData] = useSessionStorage(
    "honeypotSession",
    {}
  );

  const pb = new PocketBase(import.meta.env.VITE_BK_SUPPORT_URL);

  const captureScreen = async () => {
    setTakingScreenshot(true);
    // wait for layout change
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const html = document.documentElement;

    rasterizeHTML
      .drawHTML(html.innerHTML, null, {
        width: window.innerWidth,
        height: window.innerHeight,
      })
      .then((renderResult) => {
        const context = canvasRef.current.getContext("2d");
        canvasRef.current.width = renderResult.image.width;
        canvasRef.current.height = renderResult.image.height;
        context.drawImage(renderResult.image, 0, 0);
        console.log(canvasRef.current.toDataURL());
        setImgData(canvasRef.current.toDataURL());
      });
  };

  React.useEffect(() => {
    if (honeypotSessionData) {
      setEmail(honeypotSessionData.email);
    }
  }, [honeypotSessionData]);

  React.useEffect(() => {
    if (window) {
      window.onerror = function (error, url, line) {
        const err = {
          acc: "error",
          data: "ERR:" + error + " URL:" + url + " L:" + line,
        };
        setErros([...errors, err]);
      };
    }
  }, []);

  const submitReport = async (e) => {
    e.preventDefault();
    try {
      const data = {
        ...honeypotSessionData,
        errors,
        comment,
        location: window.location.pathname,
        browser: window.navigator.userAgent,
        website: "p8s1415ku8a7p23",
        status: "open",
      };
      await pb.collection("reports").create(data);
      toggleNotification(true);
      setShowReport(false);
    } catch (err) {
      console.log("nop");
      console.log(err);
    }
  };

  return (
    <>
      <div className="fixed bottom-5 right-5 ">
        <button
          type="button"
          onClick={() => setShowReport(true)}
          className="rounded-full bg-indigo-600 p-2  text-white shadow-xs hover:bg-indigo-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
            />
          </svg>
        </button>
      </div>

      <Modal
        open={showReport}
        setOpen={setShowReport}
        className={classNames(takingScreenshot ? "hidden" : "")}
        centerFullScreen={true}
      >
        <div className="min-w-min max-w-md p-4 ">
          <div className="text-center">
            <h2 className="text-2xl font-bold">Reportar un error</h2>
            <p className="mt-4">
              Si encuentras un error en la aplicación, por favor envía un
              reporte para que podamos solucionarlo.
            </p>
          </div>

          {/* Agrega un comentario adicional al reporte */}
          <form onSubmit={submitReport} className="space-y-4">
            <div>
              <label
                htmlFor="email"
                className="block text-sm/6 font-medium text-gray-900"
              >
                Email
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="you@example.com"
                  className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-blue-cyt-alt sm:text-sm/6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="comment"
                className="block text-sm/6 font-medium text-gray-900"
              >
                Descripción
              </label>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                name="comment"
                id="comment"
                rows="4"
                className="mt-2 block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              ></textarea>
            </div>

            {/*
             TODO: Add screenshoot
              <button
              onClick={captureScreen}
              type="button"
              className="rounded-md w-full block bg-gray-100 hover:bg-gray-200"
            >
              Adjuntar captura de pantalla
            </button> */}

            <button
              type="submit"
              className="rounded-full bg-blue-cyt px-3 py-1.5 text-sm font-semibold text-white shadow-xs hover:bg-blue-cyt-alt focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-cyt-alt"
            >
              Enviar
            </button>
          </form>
        </div>
      </Modal>

      <Notification
        open={showNotification}
        setOpen={toggleNotification}
        timeOut={10000}
        title="El reporte ha sido enviado con éxito"
      />

      <canvas className="w-full h-auto hidden" ref={canvasRef} />

      {imgData && (
        <Modal width="w-[1200px]" open={true}>
          <div>
            <Annotorious>
              <ImageAnnotator containerClassName="annotation-layer">
                <img src={imgData} className="w-full h-auto block" />
              </ImageAnnotator>
            </Annotorious>
          </div>
        </Modal>
      )}
    </>
  );
}
