/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { Fragment, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Dialog, Transition } from "@headlessui/react";
import { Outlet } from "react-router-dom";
import ProListings from "@/img/pro-listings.svg";
import {
  ChartBarIcon,
  DocumentTextIcon,
  FolderIcon,
  HomeIcon,
  UserIcon,
  UserGroupIcon,
  UsersIcon,
  MenuIcon,
  LogoutIcon,
  PuzzleIcon,
  PhoneIcon,
  XIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useProfile } from "@/_actions/userActions";
import { usePlan } from "@/_actions/authActions";
import logoCYT from "./img/logo-cyt-pro-white.png";
import Modal from "@/components/overlays/Modal";
import LogoPro from "/src/pages/login/img/logo-blue.svg";
import RoundButton from "@/components/forms/RoundButton";
import ReportBug from "@/components/forms/ReportBug";

const navigation = [
  {
    name: "Contactos",
    href: "/contacts",
    icon: UsersIcon,
    plan: ["pro", "individual", "mid"],
    role: ["owner", "admin", "broker"],
    customRules: [],
  },
  {
    name: "Propiedades",
    href: "/properties",
    icon: HomeIcon,
    plan: ["pro", "individual", "mid"],
    role: ["owner", "admin", "broker"],
    customRules: [],
  },
  {
    name: "Cuenta",
    href: "/profile",
    icon: UserIcon,
    plan: ["pro", "individual", "mid"],
    role: ["owner", "admin", "broker"],
    customRules: [],
  },
  {
    name: "Reportes",
    href: "/reports",
    icon: ChartBarIcon,
    plan: ["pro", "mid"],
    addon: "client_reports",
    role: ["owner", "admin", "broker"],
    customRules: [],
  },
  {
    name: "Usuarios",
    href: "/users",
    icon: UserGroupIcon,
    plan: ["pro", "individual", "mid"],
    role: ["owner", "admin"],
    customRules: [],
  },
  {
    name: "Inventario CYT",
    href: "/inventory",
    icon: FolderIcon,
    plan: ["pro", "mid"],
    addon: "cyt_inventory",
    role: ["owner", "admin", "broker"],
    customRules: [],
  },
  {
    name: "Integraciones",
    href: "/integrations",
    icon: PuzzleIcon,
    plan: ["pro", "mid"],
    addon: "crm_integration",
    role: ["owner", "admin"],
    customRules: [],
  },
  {
    name: "Recibos",
    href: "/receipts",
    icon: DocumentTextIcon,
    plan: ["pro", "individual", "mid"],
    role: ["owner", "admin"],
    customRules: [],
  },
  {
    name: "Contáctanos",
    href: "/contacto",
    icon: PhoneIcon,
    plan: ["pro", "individual", "mid"],
    role: ["owner", "admin", "broker"],
    customRules: [],
  },
  {
    name: "Investigaciones",
    href: "/mica",
    id: "Menu-Investigaciones",
    icon: SearchIcon,
    plan: [],
    role: ["owner", "admin", "broker"],
    customRules: "mica",
  },
  {
    name: "Pro Listings",
    href: "/pro-listings",
    id: "Menu-Pro-Listings",
    icon: ProListings,
    isSvg: true,
    plan: [],
    role: ["owner", "admin", "broker"],
    customRules: "pro_listings",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Index() {
  const {
    data: profile,
    isLoading,
    mutate: mutateProfile,
    error,
  } = useProfile();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { data: plan } = usePlan();
  const [showWelcome, setShowWelcome] = useState(false);
  const role = profile?.role;
  const paths = ["/properties", "/contacts"];
  const [customRules, setCustomRules] = useState([]);

  const { pathname } = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    mutateProfile();
    if (profile?.business_name == "" && paths.indexOf(pathname) > -1) {
      setShowWelcome(true);
    } else {
      setShowWelcome(false);
    }
  }, [profile, pathname]);
  useEffect(() => {
    if (profile?.custom_rules) {
      let rules = profile?.custom_rules.map((item) => {
        if (item.value) {
          return item.name;
        }
      });
      setCustomRules(rules);
    }
  }, [profile]);

  async function signOut() {
    try {
      await Auth.signOut();
      navigate(`/login`);
    } catch (error) {
      $log.error("error signing out: ", error);
    }
  }

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-dark">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src={logoCYT}
                      alt="logo-cyt-pro-white.decbd796"
                    />
                  </div>
                  <nav className="mt-5 space-y-1 px-2 text-gray-300">
                    {navigation.map((item) =>
                      customRules.includes(item.customRules) ||
                      (plan &&
                        (item.plan.includes(plan?.name) ||
                          plan?.plan_addons[item.addon]) &&
                        item.role.includes(role)) ? (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.href === pathname
                              ? "bg-primary-default text-white"
                              : "hover:bg-primary-default hover:text-white",
                            "group flex items-center rounded-md px-2 py-2 text-base font-medium"
                          )}
                          onClick={() => setSidebarOpen(false)}
                        >
                          {item?.isSvg ? (
                            <img
                              alt={item.name}
                              className={classNames(
                                item.href === pathname
                                  ? "text-white"
                                  : "group-hover:text-white",
                                "mr-3 h-6 w-6 flex-shrink-0"
                              )}
                              referrerPolicy="no-referrer-when-downgrade"
                              src={item.icon}
                            />
                          ) : (
                            <item.icon
                              className={classNames(
                                item.href === pathname
                                  ? "text-white"
                                  : "group-hover:text-white",
                                "mr-3 h-6 w-6 flex-shrink-0"
                              )}
                              aria-hidden="true"
                            />
                          )}
                          {item.name}
                        </Link>
                      ) : null
                    )}
                  </nav>
                </div>
                <nav className="mt-5 p-2">
                  <a
                    onClick={signOut}
                    className="cursor-pointer p-4 text-gray-300 hover:text-gray-500 group-hover:text-white"
                  >
                    <LogoutIcon className="mr-4 inline h-6 w-6 flex-shrink-0  " />
                    <span>Cerrar sesión</span>
                  </a>
                </nav>
                <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                  <Link to="/profile" className="group block flex-shrink-0">
                    <div className="flex items-center">
                      <div>
                        <img
                        /*className="inline-block h-10 w-10 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""*/
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-gray-300 group-hover:text-white">
                          {profile?.first_name} {profile?.last_name}
                        </p>
                        <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">
                          Ver perfil
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-dark">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                <img
                  className="h-8 w-auto"
                  src={logoCYT}
                  alt="ogo-cyt-pro-white"
                />
              </div>
              <nav className="mt-5 flex-1 space-y-1 px-2 text-gray-300">
                {navigation.map((item) =>
                  customRules.includes(item.customRules) ||
                  (plan &&
                    (item.plan.includes(plan?.name) ||
                      plan?.plan_addons[item.addon]) &&
                    item.role.includes(role)) ? (
                    <Link
                      id={item?.id}
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.href === pathname
                          ? "bg-blue-900"
                          : "hover:bg-primary-default hover:text-white",
                        "group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                      )}
                    >
                      {item?.isSvg ? (
                        <img
                          alt={item.name}
                          className={classNames(
                            item.href === pathname
                              ? "text-white"
                              : "group-hover:text-white",
                            "mr-3 h-6 w-6 flex-shrink-0"
                          )}
                          referrerPolicy="no-referrer-when-downgrade"
                          src={item.icon}
                        />
                      ) : (
                        <item.icon
                          className={classNames(
                            item.href === pathname
                              ? "text-white"
                              : "group-hover:text-white",
                            "mr-3 h-6 w-6 flex-shrink-0"
                          )}
                          aria-hidden="true"
                        />
                      )}
                      {item.name}
                    </Link>
                  ) : null
                )}
              </nav>
            </div>
            <nav className="mt-5 p-2">
              <a
                onClick={signOut}
                className="cursor-pointer p-4 text-gray-100 hover:text-gray-500 group-hover:text-gray-500 "
              >
                <LogoutIcon className="mr-4 inline h-6 w-6 flex-shrink-0" />
                <span>Cerrar sesión</span>
              </a>
            </nav>
            <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
              <Link to="/profile" className="group block w-full flex-shrink-0">
                <div className="flex items-center">
                  <div>
                    <img
                    /*className="inline-block h-9 w-9 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""*/
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-400 group-hover:text-gray-200">
                      {profile?.first_name} {profile?.last_name}
                    </p>
                    <p className="text-xs font-medium text-gray-400 group-hover:text-gray-200">
                      Ver perfil
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 bg-white pl-1 pt-1 shadow-md sm:pl-3 sm:pt-3 md:hidden ">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className=" min-h-screen">
            <div className="">
              <div className="m-0 bg-white p-0 shadow-md lg:p-4 ">
                {/* <div className=""> */}
                <Outlet />
                {/* </div> */}
              </div>
            </div>
          </main>
          <Modal open={showWelcome} centerFullScreen={false}>
            <div className="flex w-full flex-col items-center justify-center p-8 text-[#020024]">
              <span className="mb-3 text-center text-xl">
                ¡Hola! Te damos la bienvenida a
              </span>
              <img src={LogoPro} alt="cyt pro logo" className="w-[230px]" />
              <span className="mt-3 text-center text-xl text-primary-default">
                Completa los datos de tu cuenta
              </span>
              <span className="text-center text-xl text-[#020024]">
                para publicar propiedades 🏘
              </span>
              <a
                href="/profile"
                alt="profile link"
                className="mt-6 w-full max-w-[212px]"
              >
                <RoundButton active={true} text="Ir a cuenta" />
              </a>
            </div>
          </Modal>
        </div>
      </div>

      <ReportBug />
    </>
  );
}
