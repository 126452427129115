import React from "react";
import {
  UseAnalytics,
  contactsParamsAtom,
  UseManagedLeadsAnalytics,
} from "@/_actions/contactActions";
import { useAtom } from "jotai";
import { formatISO } from "date-fns";
import PropTypes from "prop-types";
import classNames from "classnames";

export default function Analytics({ managedLeads, leadsQuantity }) {
  const [contactsParams] = useAtom(contactsParamsAtom);
  const created__gt = contactsParams?.created_range?.split(",")[0]
    ? formatISO(new Date(contactsParams.created_range.split(",")[0]))
    : null;
  const created__lt = contactsParams?.created_range?.split(",")[1]
    ? formatISO(new Date(contactsParams.created_range.split(",")[1]))
    : null;

  const { data: whatsappData } = UseAnalytics({
    event_type: "click",
    event_value: "contact_whatsapp",
    created__gt,
    created__lt,
  });

  const { data: callData } = UseAnalytics({
    event_type: "click",
    event_value: "contact_call",
    created__gt,
    created__lt,
  });

  const { data: managedData } = UseManagedLeadsAnalytics({
    created__gt,
    created__lt,
  });

  const getStyles = (quantity) => {
    if (quantity > 15) return { bg: "bg-blue-200", text: "text-gray-700" };
    if (quantity > 10) return { bg: "bg-yellow-200", text: "text-gray-700" };
    if (quantity > 5) return { bg: "bg-amber-300", text: "text-gray-700" };
    if (quantity > 2) return { bg: "bg-orange-400", text: "text-gray-700" };
    return { bg: "bg-red-500", text: "text-white" }; // 0 leads: fondo rojo y texto blanco
  };

  const { bg, text } = getStyles(leadsQuantity);

  return (
    <div className="w-full p-2 gap-2 grid lg:grid-cols-2 p">
      <AnalyticsCard
        count={whatsappData?.count}
        label="Contactos directos en WhatsApp"
      />
      <AnalyticsCard count={callData?.count} label="Llamadas recibidas" />

      {managedLeads && (
        <>
          <AnalyticsCard
            count={managedData?.total_count}
            label="Contactos perfilados"
          />
          <AnalyticsCard
            count={leadsQuantity}
            label="Contactos perfilados restantes"
            extraClass={bg}
            textClass={text}
          />
        </>
      )}
    </div>
  );
}
const AnalyticsCard = ({
  count,
  label,
  extraClass = "",
  textClass = "text-gray-600",
}) => (
  <div
    className={classNames(
      "card m-0 flex items-center space-x-3 rounded-md p-3 shadow-sm",
      extraClass
    )}
  >
    <p className={classNames("text-xl font-bold", textClass)}>{count ?? 0}</p>
    <div className={classNames("flex items-center text-xs", textClass)}>
      {label}
    </div>
  </div>
);

Analytics.propTypes = {
  managedLeads: PropTypes.bool,
  leadsQuantity: PropTypes.number,
};

AnalyticsCard.propTypes = {
  count: PropTypes.number,
  label: PropTypes.string.isRequired,
  extraClass: PropTypes.string,
  textClass: PropTypes.string,
};
