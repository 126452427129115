import React, { useState } from "react";
import {
  MailIcon,
  PhoneIcon,
  CheckCircleIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { useAtom } from "jotai";
import { useContacts, contactsParamsAtom } from "@/_actions/contactActions";
import Modal from "@/components/overlays/Modal";
import Notification from "@/components/overlays/Notification";
import WhatsAppIcon from "../contact/img/solid-whatsapp.svg";
import SolidPhoneIcon from "../contact/img/solid-phone.svg";
import ContactMeIcon from "../contact/img/solid_contact_me.svg";
import FireIcon from "../contact/img/solid-fire.svg";
import StarIcon from "../contact/img/solid-star.svg";
import BriefcaseIcon from "../contact/img/solid-briefcase.svg";
import EyeIcon from "../contact/img/solid-eye.svg";
import DirectoryIcon from "../contact/img/directory.svg";
import PropTypes from "prop-types";
import ContacStatusForm from "./forms/ContactStatusForm";
import ManagedIcon from "../contact/img/solid-managed.svg";

export default function ContactDetail({ contact }) {
  const [contactsParams] = useAtom(contactsParamsAtom);
  const { mutate } = useContacts(contactsParams);
  const [showNotification, toggleNotification] = useState(false);
  const [showModal, toggleModal] = useState(false);
  const [action, setAction] = useState("discarded");
  const ScoreLabel = ({ scoreLabel }) => {
    const scoreMap = {
      1: {
        text: "Super lead",
        bgColor: "bg-[#FCA5A5] text-red-700",
        color: "[#FCA5A5]",
        icon: FireIcon,
      },
      2: {
        text: "Buen lead",
        bgColor: "bg-[#FCD34D] text-yellow-700",
        color: "[#FCD34D]",
        icon: StarIcon,
      },
      3: {
        text: "Lead curioso",
        bgColor: "bg-blue-300 text-blue-600",
        color: "blue-300",
        icon: EyeIcon,
      },
      default: {
        text: "Probable broker",
        bgColor: "bg-gray-300 text-gray-600",
        color: "gray-300",
        icon: BriefcaseIcon,
      },
    };
    const { text, bgColor, color, icon } =
      scoreMap[scoreLabel] || scoreMap.default;
    return (
      <div className="flex items-center">
        <div className={`w-46 ml-1 mt-3 flex items-center ${bgColor}`}>
          <div className="h-0 w-0 border-t-[16px] border-l-[7px] border-b-[16px] border-l-white border-t-transparent border-b-transparent"></div>
          <div className="flex items-center justify-items-center rounded-full py-2 px-3 font-bold">
            <img alt={`${text} icon`} className="mx-2 w-4" src={icon} />
            <span className="text-xs">{text}</span>
          </div>
        </div>
        <div
          className={`mt-3 h-0 w-0 border-t-[16px] border-l-[9px] border-b-[16px] border-t-transparent border-b-transparent border-l-${color}`}
        ></div>
      </div>
    );
  };
  ScoreLabel.propTypes = {
    scoreLabel: PropTypes.number,
  };

  const CampaignLabel = ({ campaign, managed }) => {
    const campaignMap = {
      Whatsapp_Button: {
        text: "Contacto de whatsapp",
        bgColor: "bg-[#25D366]",
        color: "border-l-[#25D366]",
        icon: WhatsAppIcon,
      },
      Call_Button: {
        text: "Contacto por llamada",
        bgColor: "bg-[#8839E2]",
        color: "border-l-[#8839E2]",
        icon: SolidPhoneIcon,
      },
      Directory: {
        text: "Contacto del directorio",
        bgColor: "bg-orange-900",
        color: "border-l-orange-900",
        icon: DirectoryIcon,
      },
      managed: {
        text: "Contacto perfilado",
        icon: ManagedIcon,
        bgColor: "bg-[#FFCC00]",
        color: "border-l-[#FFCC00]",
      },
      default: {
        text: "Contacto de formulario",
        bgColor: "bg-yellow-cyt",
        color: "border-l-yellow-cyt",
        icon: ContactMeIcon,
      },
    };
    const { text, bgColor, color, icon } = managed
      ? campaignMap.managed
      : campaignMap[campaign] || campaignMap.default;
    return (
      <div className="flex items-center">
        <div className={`w-46 mt-3 flex items-center ${bgColor}`}>
          <div className="h-0 w-0 border-t-[16px] border-l-[7px] border-b-[16px] border-l-white border-t-transparent border-b-transparent"></div>
          <div className="flex items-center justify-items-center rounded-full py-2 px-3 font-bold text-white">
            <img alt={`${text} icon`} className="mx-2 w-4" src={icon} />
            <span className="text-xs">{text}</span>
          </div>
        </div>
        <div
          className={`mt-3 h-0 w-0 border-t-[16px] border-l-[9px] border-b-[16px] border-t-transparent border-b-transparent ${color}`}
        ></div>
      </div>
    );
  };

  CampaignLabel.propTypes = {
    campaign: PropTypes.string,
    managed: PropTypes.bool,
  };

  return (
    <>
      {contact?.id ? (
        <div className="w-full rounded-lg bg-white shadow">
          <div className="border-b border-gray-200 px-4 py-2 sm:px-6">
            <div className="-ml-4 -mt-4 flex-wrap items-center justify-between sm:flex-nowrap md:flex">
              <div className="mt-4 ml-2">
                <div className="flex w-full flex-col items-center rounded-md bg-gray-100 p-3">
                  <div className="flex w-full justify-between">
                    <h3 className="mr-2 text-lg font-semibold leading-6 text-gray-900">
                      Nombre del lead:
                    </h3>
                    <h3 className="text-lg  leading-6 text-gray-900">
                      {contact?.name}
                    </h3>
                  </div>
                  <div className="flex w-full justify-between">
                    <h3 className="mr-2 text-lg font-semibold leading-6 text-gray-900">
                      Número de teléfono:
                    </h3>
                    <p>{contact?.phone}</p>
                  </div>
                  <div className="flex w-full justify-between">
                    <h3 className="mr-2 text-lg font-semibold leading-6 text-gray-900">
                      Email:
                    </h3>
                    <p>{contact?.email}</p>
                  </div>
                </div>
              </div>
              <div className="flex-shrink-1 mt-4 ml-4 flex md:flex-shrink-0">
                {contact?.phone && (
                  <a
                    href={`tel:${contact?.phone}`}
                    data-splitbee-event="contacts-call-lead"
                  >
                    <button
                      type="button"
                      className="relative inline-flex items-center  rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <PhoneIcon
                        className="-ml-1 h-5 w-5 text-gray-400 md:mr-2"
                        aria-hidden="true"
                      />
                      <span className="hidden md:block">Llamar</span>
                    </button>
                  </a>
                )}
                {contact?.email && (
                  <a
                    href={`mailto:${contact?.email}`}
                    data-splitbee-event="contacts-email-lead"
                  >
                    <button
                      type="button"
                      className="relative ml-3 inline-flex items-center rounded-md  border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <MailIcon
                        className="-ml-1 h-5 w-5 text-gray-400 md:mr-2"
                        aria-hidden="true"
                      />
                      <span className="hidden md:block">Enviar</span>
                    </button>
                  </a>
                )}
                <button
                  type="button"
                  data-splitbee-event="contacts-contacted-lead"
                  onClick={() => {
                    toggleModal(true);
                    setAction("contacted");
                  }}
                  className="relative ml-3 inline-flex items-center rounded-md  border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <CheckCircleIcon
                    className="-ml-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
                <button
                  type="button"
                  data-splitbee-event="contacts-discarded-lead"
                  onClick={() => {
                    toggleModal(true);
                    setAction("discarded");
                  }}
                  className="relative ml-3 inline-flex items-center rounded-md  border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <TrashIcon
                    className="-ml-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="ml-4 flex">
            {contact?.score_label >= 1 && (
              <ScoreLabel scoreLabel={contact.score_label} />
            )}
            <CampaignLabel
              campaign={contact?.utm_campaign}
              managed={contact?.is_managed}
            />
          </div>

          {contact?.message ? (
            <div className="clearfix w-6/7 mx-4 my-2 flex flex-col rounded-lg bg-gray-100 p-2">
              <div className="ml-1">
                <h3 className="font-semibold">Mensaje del lead:</h3>
              </div>
              <div className="clearfix w-6/7 mx-4 rounded-lg bg-purple-50 p-2">
                <p>{contact?.message}</p>
              </div>
            </div>
          ) : null}

          <div className="grid gap-3 p-4 md:grid-cols-2">
            <div className="aspect-video w-[300px]">
              <img
                className="h-full w-full rounded-lg object-cover"
                src={contact?.image_cover}
              ></img>
            </div>
            <div className="">
              <a
                rel="noreferrer"
                target="_blank"
                href={`${import.meta.env.VITE_PORTAL_URL}/propiedad/${
                  contact?.property_id
                }`}
                data-splitbee-event="contacts-link-property-lead"
              >
                <p className="mb-2 text-blue-600 hover:text-blue-800 hover:underline">
                  {contact?.property_title}
                </p>
              </a>
            </div>
          </div>
        </div>
      ) : null}

      <Modal open={showModal} setOpen={toggleModal}>
        <ContacStatusForm
          id={contact?.id}
          action={action}
          callBack={() => {
            mutate();
            toggleModal(false);
            toggleNotification(true);
            setTimeout(() => toggleNotification(false), 3000);
          }}
        />
      </Modal>

      <Notification
        open={showNotification}
        setOpen={toggleNotification}
        title="Cambios realizados con éxito"
      />
    </>
  );
}

ContactDetail.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    score_label: PropTypes.number,
    utm_campaign: PropTypes.string,
    message: PropTypes.string,
    image_cover: PropTypes.string,
    property_title: PropTypes.string,
    property_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    is_managed: PropTypes.bool,
  }),
};

ContactDetail.defaultProps = {
  contact: null,
};
