import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { usePlan } from "@/_actions/authActions";

import Pagination from "@/components/navigation/Pagination";
import {
  useContacts,
  contactsParamsAtom,
  initialParams,
} from "@/_actions/contactActions";
import Analytics from "./Analytics";
import Filters from "./Filters";
import ContactList from "./ContactList";
import ContactDetail from "./ContactDetail";

export default function Index() {
  const [contactsParams, setContactParams] = useAtom(contactsParamsAtom);
  const { data: plan } = usePlan();
  const { data: contacts } = useContacts(contactsParams);
  const [selected, setSelected] = useState({});
  const [managedLeads, setManagedLeads] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const leadsQuantity = plan?.plan_addons?.leads_quantity ?? 0;

  useEffect(() => {
    setSelected(contacts?.results[0]);
  }, [contacts?.results[0]]);

  useEffect(() => {
    if (plan?.plan_addons?.managed_leads) {
      setManagedLeads(true);
      setContactParams((prev) => ({ ...prev, managed_leads: true }));
    }
  }, [plan, setContactParams]);

  const handleReset = () => {
    if (managedLeads) {
      setContactParams({
        page: 1,
        status: 1,
        managed_leads: true,
      });
    } else {
      setContactParams(initialParams);
    }
    setResetKey((prev) => prev + 1);
  };

  return (
    <div key={resetKey} className="sm:p-2 lg:p-4">
      <div className="flex w-full">
        <div className="w-full">
          <h1 className="title">Contactos</h1>
          <div className="">
            <div className="col-span-4 w-full">
              <Filters
                onReset={handleReset}
                onSubmit={(values) =>
                  setContactParams(() => {
                    if (values.status !== "all") {
                      return { ...values };
                    } else {
                      const r = { ...values };
                      delete r.status;
                      return r;
                    }
                  })
                }
                managedLeads={managedLeads}
              />
            </div>
          </div>
        </div>
        {plan?.plan_addons.contact_analytics ? (
          <div className="h-full">
            <Analytics
              managedLeads={managedLeads}
              leadsQuantity={leadsQuantity}
            />
          </div>
        ) : null}
      </div>
      {managedLeads && leadsQuantity <= 5 && (
        <div className="w-full border border-red-200 bg-red-50 text-red-600 p-3 mt-3 rounded-md text-center font-semibold">
          {leadsQuantity > 0 ? (
            <>
              ⚠️ ¡Atención! Solo te quedan{" "}
              <strong className=" underline">{leadsQuantity}</strong> leads
              disponibles. Comunícate con tu asesor para adquirir más.
            </>
          ) : (
            <>⚠️ ¡Alerta! Ya te quedaste sin leads.</>
          )}
        </div>
      )}

      <div className="my-2 md:grid md:grid-cols-5">
        <div className="col-span-1">
          <ContactList
            contacts={contacts?.results}
            selected={selected?.id}
            onSelect={setSelected}
            contact={selected}
          />
        </div>
        <div className="col-span-4 hidden md:flex">
          <ContactDetail contact={selected} />
        </div>
      </div>
      <Pagination
        total={contacts?.count}
        type={"Contacto"}
        current={contactsParams.page}
        next={contacts?.next}
        previous={contacts?.previous}
        pageSize={10}
        onPageChange={(page) => setContactParams((prev) => ({ ...prev, page }))}
      />
    </div>
  );
}
