import React, { useState } from "react";
import PropTypes from "prop-types";
import { parse, formatRelative } from "date-fns";
import { es } from "date-fns/locale";
import Modal from "@/components/overlays/Modal";
import ContactDetail from "./ContactDetail";
import WhatsAppIcon from "../contact/img/solid-whatsapp.svg";
import SolidPhoneIcon from "../contact/img/solid-phone.svg";
import ContactMeIcon from "../contact/img/solid_contact_me.svg";
import FireIcon from "../contact/img/solid-fire.svg";
import StarIcon from "../contact/img/solid-star.svg";
import BriefcaseIcon from "../contact/img/solid-briefcase.svg";
import EyeIcon from "../contact/img/solid-eye.svg";
import DirectoryIcon from "../contact/img/directory.svg";
import ManagedIcon from "../contact/img/solid-managed.svg";

const formatDate = (created) => {
  const parsedDate = parse(created, "dd-MM-yyyy HH:m:s", new Date());
  return formatRelative(parsedDate, new Date(), { locale: es });
};

const ContactIcon = ({ scoreLabel }) => {
  const iconMap = {
    1: { icon: FireIcon, bgColor: "bg-[#FCA5A5]", color: "border-l-[#FCA5A5]" },
    2: { icon: StarIcon, bgColor: "bg-[#FCD34D]", color: "border-l-[#FCD34D]" },
    3: { icon: EyeIcon, bgColor: "bg-blue-300", color: "border-l-blue-300" },
    default: { icon: BriefcaseIcon, color: "bg-gray-300" },
  };

  const { icon, bgColor, color } = iconMap[scoreLabel] || iconMap.default;

  return (
    <div className="flex items-center">
      <div className={`-ml-1 flex w-full items-center ${bgColor}`}>
        <div className="h-0 w-0 border-t-[8px] border-l-[4px] border-b-[8px] border-l-white border-t-transparent border-b-transparent"></div>
        <div className="flex">
          <img alt="campaign icon" className="mx-3 w-3.5" src={icon} />
        </div>
      </div>
      <div
        className={`h-0 w-0 border-t-[8px] border-l-[4px] border-b-[8px] ${color} border-t-transparent border-b-transparent`}
      ></div>
    </div>
  );
};

ContactIcon.propTypes = {
  scoreLabel: PropTypes.number,
};

const CampaignIcon = ({ campaign, managed }) => {
  const campaignMap = {
    Whatsapp_Button: {
      icon: WhatsAppIcon,
      bgColor: "bg-[#25D366]",
      color: "border-l-[#25D366]",
    },
    Call_Button: {
      icon: SolidPhoneIcon,
      bgColor: "bg-[#8839E2]",
      color: "border-l-[#8839E2]",
    },
    Directory: {
      icon: DirectoryIcon,
      bgColor: "bg-orange-900",
      color: "border-l-orange-900",
    },
    managed: {
      icon: ManagedIcon,
      bgColor: "bg-[#FFCC00]",
      color: "border-l-[#FFCC00]",
    },
    default: {
      icon: ContactMeIcon,
      bgColor: "bg-yellow-cyt",
      color: "border-l-yellow-cyt",
    },
  };

  const { icon, bgColor, color } = managed
    ? campaignMap.managed
    : campaignMap[campaign] || campaignMap.default;

  return (
    <div className="flex items-center">
      <div className={`flex w-full items-center ${bgColor}`}>
        <div className="h-0 w-0 border-t-[8px] border-l-[4px] border-b-[8px] border-l-white border-t-transparent border-b-transparent"></div>
        <div className="flex">
          <img alt="campaign icon" className="mx-3 w-3.5" src={icon} />
        </div>
      </div>
      <div
        className={`h-0 w-0 border-t-[8px] border-l-[4px] border-b-[8px] ${color} border-t-transparent border-b-transparent`}
      ></div>
    </div>
  );
};

CampaignIcon.propTypes = {
  campaign: PropTypes.string,
  managed: PropTypes.bool,
};

export default function ContactList({ contacts, selected, onSelect, contact }) {
  const [showModal, toggleModal] = useState(false);
  const mobile = window.innerWidth < 770;
  return (
    <>
      <div className="space-y-1">
        {contacts?.map((contact) => (
          <div
            className={`button-secondary ${
              contact?.id === selected ? "bg-secondary-hover" : ""
            } space-y-1 border-gray-300`}
            key={contact?.id}
            onClick={() => {
              onSelect(contact);
              toggleModal(mobile);
            }}
          >
            <div className="flex justify-between">
              <p className="text-xs font-medium">
                {contact.name.length > 30
                  ? `${contact.name.substring(0, 30)}...`
                  : contact.name}
              </p>
              <div className="flex">
                {contact?.score_label >= 1 && (
                  <ContactIcon scoreLabel={contact.score_label} />
                )}
                <CampaignIcon
                  campaign={contact.utm_campaign}
                  managed={contact.is_managed}
                />
              </div>
            </div>
            <p className="text-sm text-gray-600">
              {formatDate(contact.created)}
            </p>
            <p className="truncate text-xs text-gray-500">
              {contact?.property_title}
            </p>
          </div>
        ))}
      </div>
      <Modal open={showModal} setOpen={toggleModal} className="flex md:hidden">
        <ContactDetail contact={contact} />
      </Modal>
    </>
  );
}

ContactList.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string.isRequired,
      score_label: PropTypes.number,
      utm_campaign: PropTypes.string,
      property_title: PropTypes.string,
      created: PropTypes.string.isRequired,
    })
  ).isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func.isRequired,
  contact: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    score_label: PropTypes.number,
    utm_campaign: PropTypes.string,
    property_title: PropTypes.string,
    created: PropTypes.string.isRequired,
    is_managed: PropTypes.bool,
  }),
};

ContactList.defaultProps = {
  selected: null,
  contact: null,
};
